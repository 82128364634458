// Stylesheets
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'vendor/leap/pages/assets/css/theme.css';
import 'vendor/leap/pages/assets/css/theme-desktop-app.css';
import 'vendor/leap/pages/assets/css/theme-saas.css';
import 'vendor/leap/pages/assets/css/theme-coworking.css';
import 'vendor/leap/pages/assets/css/theme-software-library.css';
import 'vendor/leap/pages/assets/css/loaders/loader-typing.css';

// Javascripts
import 'owl.carousel';

// Fonts
require.context('vendor/leap/pages/assets/fonts', true, /\.(css)$/im);
require.context('vendor/feather', true, /\.(css)$/im);

// images
require.context('vendor/leap/pages/assets/img', true, /\.(png|jpg|svg)$/im);
require.context('vendor/feather', true, /\.(png|jpg|svg)$/im);
